import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import PageBuilder from '../../components/common/PageBuilder/PageBuilder'
import PageIntro from '../../components/common/PageIntro'
import { Helmet } from 'react-helmet'

const Page = ({ data: { strapiPages } }) => {
	return (
		<Layout>
			<Helmet>
				<title>Delta Insurance | {strapiPages.Title}</title>
				{strapiPages.MetaTitle != null ? (
					<meta property="og:title" content={strapiPages.MetaTitle} />
				) : (
					<meta name="og:title" content={strapiPages.Title} />
				)}
				{strapiPages.MetaDescription != null ? (
					<meta name="description" content={strapiPages.MetaDescription} />
				) : (
					<meta name="title" content={strapiPages.Description} />
				)}
				{strapiPages.MetaKeywords != null ? (
					<meta name="keywords" content={strapiPages.MetaKeywords} />
				) : null}
				{strapiPages.MetaImage != null ? (
					<meta
						property="og:image"
						content={strapiPages.MetaImage.formats.small.url}
					/>
				) : null}
			</Helmet>
			<h1 class="visually-hidden">{strapiPages.Title}</h1>
			<PageIntro
				title={strapiPages.Title}
				summary={strapiPages.Paragraph}
				image={strapiPages.HeroImage}
			/>
			<PageBuilder data={strapiPages.pageBuilder} />
		</Layout>
	)
}

export default Page

export const query = graphql`
	query ArticleQuery($Slug: String!) {
		strapiPages(Slug: { eq: $Slug }) {
			Slug
			Title
			Paragraph
			pageBuilder
			HeroImage {
				provider_metadata {
					public_id
				}
				localFile {
					childImageSharp {
						fluid {
							base64
							tracedSVG
							srcWebp
							srcSetWebp
							originalImg
							originalName
						}
					}
				}
				formats {
					medium {
						url
					}
					small {
						url
					}
					thumbnail {
						url
					}
				}
			}
			MetaTitle
			MetaKeywords
			MetaDescription
			MetaImage {
				formats {
					small {
						url
					}
				}
			}
		}
	}
`
